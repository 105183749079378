import Header from "./components/Header"
import Footer from "./components/Footer";
import { useContext,  useRef, useState } from "react";
import { LocationContext } from "../App";
import { Login } from "./components/StockLoginAPI";
import "./StockLoginPage.css";

const StockLoginPage = () => {
    const {languageBook} = useContext(LocationContext);

    let memoIdLoacal;
    let memoPasswordLocal;

    localStorage.getItem("memo-id")==="true" ? memoIdLoacal = true : memoIdLoacal = false;
    localStorage.getItem("memo-password")==="true" ? memoPasswordLocal = true : memoPasswordLocal = false;

    const [userId, setUserId] = useState(localStorage.getItem("stock-id"));
    const [userPassword, setUserPassword] = useState(localStorage.getItem("stock-password"));
    const [showPassword, setShowPassword] = useState(false);
    const [memoId, setMemoId] = useState(memoIdLoacal);
    const [memoPassword, setMemoPassword] = useState(memoPasswordLocal);


    const idInput = useRef(null);
    const passwordInput = useRef(null);

    const handleIdOnChange = (e) => {
        setUserId(e.target.value)
    }
    const handlePasswordOnChange = (e) => {
        setUserPassword(e.target.value)
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    // useEffect(() => {

    // }, [memoId, memoPassword])

    const cleanInput = () => {
        setUserId("");
        setUserPassword("");
    }

    return(
        <>
            <Header />
                <main>
                    <section id="login-sec" className="reading-sec">
                        <h1 className="text-gradient" id="stock-login-tilte">股票平台登入</h1>
                        <form action="" id="login-form">
                            <label htmlFor="">{languageBook.stockAccount}</label>
                            <input ref={idInput} type="text" value={userId} onChange={handleIdOnChange} required autoComplete="username" />
                            <label htmlFor="">{languageBook.stockPassword}</label>
                            <div id="password-wrapper">
                                <input ref={passwordInput} type={showPassword? "text" : "password"} value={userPassword} onChange={handlePasswordOnChange} required autoComplete="current-password" />
                                <div onClick={toggleShowPassword}>
                                    <img src={showPassword? "/pictures/visibility_off_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" : "/pictures/visibility_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"} alt="show password" />
                                </div>
                            </div>
                            
                            <div id="memo-checkboxes">
                                <Checkbox id="memo-account" labelText="記住帳號" isChecked={memoId} setChecked={setMemoId}/>
                                <Checkbox id="memo-password" labelText="記住密碼" isChecked={memoPassword} setChecked={setMemoPassword}/>
                            </div>

                            <button type="submit" onClick={(e) => Login(e, userId, userPassword, cleanInput, memoId, memoPassword)}>{languageBook.stockLoginBTN}</button>
                        </form>
                    </section>
                </main>
                
            <Footer />
        </>
    )
}

export default StockLoginPage;

const Checkbox = ({id, labelText, isChecked, setChecked}) => {
    const handleOnClick = () => {
        setChecked(!isChecked);
    }

    return(
        <div className="checkbox-wrapper">
            <input id={id} type="checkbox" checked={isChecked} onClick={handleOnClick}/>
            <label htmlFor={id}>{labelText}</label>
        </div>
    )
}