import { useEffect, useRef } from "react";
import "./Dialog.css";

const Dialog = ({dialogId, isDialogOpen, setIsDialogOpen, setRuleChecked, elements}) => {
    const dialog = useRef(null);
    useEffect(() => {
        isDialogOpen? dialog.current.showModal() : dialog.current.close();
    }, [isDialogOpen])
    
    const handleClose = () => {
        setIsDialogOpen(false);
    }

    const handleCheck = () => {
        setRuleChecked(true);
        setIsDialogOpen(false);
    }
    const handleCancel = () => {
        setRuleChecked(false);
        setIsDialogOpen(false);
    }

    useEffect(() => {
        const outsideClose = (event) => {
            if(isDialogOpen && !dialog.current.contains(event.target)){
                console.log(event.target);
                console.log("close");
                handleClose();
            }
        }
        if(isDialogOpen){
            document.addEventListener("mousedown", outsideClose);
            console.log("add");
        } else{
            document.removeEventListener("mousedown", outsideClose);
        }

        return() => {
            document.removeEventListener("mousedown", outsideClose);
        }    
    });

    return(
        <dialog id={dialogId} className="rules-dia" ref={dialog}>
            <button className="close-btn"><img src="/pictures/close_24dp_FILL0_wght400_GRAD0_opsz24.svg" alt="close" onClick={handleClose} /></button>
            {elements}
            <div id="agree-btns">
                <button id="disagree" onClick={handleCancel}>我不同意遵守相關交易規範</button>
                <button id="agree" onClick={handleCheck}>我已詳細閱讀同意遵守相關交易規範</button>
            </div>
        </dialog>
    )
}

export default Dialog;