import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { LocationContext } from "../App";
import { useContext } from "react";
import "./NotAvailable.css";

const NotAvailable = () => {
    // const {languageBook, urlData} = useContext(LocationContext);
    const { urlData} = useContext(LocationContext);

    return(
        <>
            <Header />
                <main>
                    <section id="not-available-sec" className="reading-sec">
                        <h1>Nope</h1>
                        <p>
                            不好意思，您所在的地區尚未開放本服務
                        </p>
                        <p>
                            請與我們聯絡以獲取更多資訊。
                        </p>
                        <div>
                            <Link to={`${urlData.location}/`} className="button">回首頁</Link>
                            <Link to={`${urlData.location}/#line-sec`} className="button">聯絡我們</Link>
                        </div>
                    </section>
                </main>
                
            <Footer />
        </>
    )
}

export default NotAvailable;