import { useState, useEffect, useRef, useContext } from "react";
import { LocationContext } from "../../App";

const DeliverySec = () => {
    const profitCard = useRef(null);
    const lossCard = useRef(null);
    const [clientProfit, setClientProfit] = useState(true);
    const {languageBook} = useContext(LocationContext);

    useEffect(() => {
        const handleProfitCardClick = () => {
            setClientProfit(true);
        };
        const handleLossCardClick = () => {
            setClientProfit(false);
        };
        profitCard.current.addEventListener("mouseover", handleProfitCardClick);
        lossCard.current.addEventListener("mouseover", handleLossCardClick);
    },[]);

    useEffect(() => {
        if(clientProfit) {
            profitCard.current.classList.add("delivery-card-focus");
            lossCard.current.classList.remove("delivery-card-focus");
        } else {
            lossCard.current.classList.add("delivery-card-focus");
            profitCard.current.classList.remove("delivery-card-focus");
        }
    }, [clientProfit]);

    return(
        <section id="delivery-sec" className="reading-sec fade-sec">
                <div id="delivery-dis" className="js-scroll-anim">
                    <h2 className="text-center">{languageBook.deliveryTitle}</h2>
                    <p className="text-center">{languageBook.deliveryDesc}</p>
                </div>
                <div id="delivery-cards" className="js-scroll-anim">
                    <div id="profit-card" className="card delivery-card" ref={profitCard}>
                        <h3 className="text-center">{languageBook.profitTitle}</h3>
                        <p className="text-center">{languageBook.profitDesc}</p>
                    </div>
                    <div id="loss-card" className="card delivery-card" ref={lossCard}>
                        <h3 className="text-center">{languageBook.lossTitle}</h3>
                        <p className="text-center">{languageBook.lossDesc}</p>
                    </div>
                </div>
                <fieldset id="delivery-amount" className="js-scroll-anim">
                    <legend className="text-center">{languageBook.deliveryAmount}</legend>
                    <p id="delivery-amount-text" className="text-center">{languageBook.deliveryWay}</p>
                </fieldset>
        </section>
    )
}

export default DeliverySec;