import { useState, useEffect, useRef } from "react";
import StockAPI from "./StockAPI";
import "./FinacialProducts.css";

const FinacialProducts = ({ id }) => {
    const finacialProducts = useRef(null);
    const [ finacialInfo, setFinacialInfo ] = useState({});
    const [ products, setProducts ] = useState([]);
    const [ showProducts, setShowProducts ] = useState([]);
    const [ windowWidth, setWindowWidth ] = useState(0);
    const [ showNum, setShowNum ] = useState(0);
    const [ round, setRound ] = useState(0);

    const productWidth = 180;

    /////////////////////////////////
    // turn datas into product cards
    useEffect(() => {
        let products = [];
        const productKeys = Object.keys( finacialInfo );
        productKeys.map( productKey => {
            const product = <Product 
                                name = {finacialInfo[productKey].name}
                                stockID = {finacialInfo[productKey].symbol}
                                price = {finacialInfo[productKey].price}
                                change = {finacialInfo[productKey].changePrice}
                                changePercent = {finacialInfo[productKey].changePercent}
                                key = {productKey}
                            />;
            products.push(product);
            return() => console.log("add products");
        })

        let emptyIndex = 0;
        while( products.length % showNum > 0 ){
            const empty = <Product 
                name = "--"
                stockID = "--"
                price = "--"
                change = "--"
                changePercent = "--"
                key = {emptyIndex}
            />;
            
            products.push(empty);
            emptyIndex++;
        }

        setProducts(products);
    }, [finacialInfo, showNum]);
    
    /////////////////////////////////
    // get window width
    useEffect(() => {
        const getWindowWidth = () => {
            setWindowWidth( window.innerWidth );
        }

        getWindowWidth();
        window.addEventListener( "resize", getWindowWidth );

        return(() => {
            if(window) {
                window.removeEventListener( "resize", getWindowWidth );
            }
        })
    }, [])

    /////////////////////////////////
    // calcu how many cards can fit in
    useEffect(() => {
        setShowNum( parseInt((windowWidth ) / productWidth) );
    }, [windowWidth]);

    useEffect(() => {
        let showProducts = [];
        for(let i = 0; i < showNum; i++ ) {
            const productContainer = <ProductContainer products={products} data={finacialInfo} index={i} key={i} round={round} showNum={showNum} />
            showProducts.push(productContainer);
        }

        setShowProducts( showProducts );
    }, [products, showNum, round, finacialInfo]);

    /////////////////////////////////
    // flip interval
    useEffect(() => {
        const finacialProductsCurrent = finacialProducts.current;
        const nextRound = () => {
            setRound( round + 1 );          
        }

        const flipInterval = setInterval( () => {
            nextRound();
        }, 1000* 2);

        finacialProductsCurrent.addEventListener( "click", nextRound);

        return( () => {
            clearInterval(flipInterval);
            if(finacialProductsCurrent) {
                finacialProductsCurrent.removeEventListener( "click", nextRound);
            }
        })
    }, [round, products.length, showNum])


    return(
        <>
            <StockAPI setFinacialInfo={setFinacialInfo} />
            <div id={id} className="finacial-products" ref={finacialProducts}>
                {showProducts}
            </div>
        </>
    )
}

export default FinacialProducts;


const ProductContainer = ({products, data, index, showNum, round}) => {    
    const productContainer = useRef(null);
    const maxRound = parseInt(products.length / showNum);
    const [ roundCache, setroundCache ] = useState(0);
    // const [ showProducts , setShowProducts] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setroundCache(round);
        }, index* 100)
        
    }, [round, index])

    useEffect(() => {
        const productContainerCurrent = productContainer.current;
        const adding = productContainerCurrent.firstChild;
        const removed = productContainerCurrent.lastChild;

        class FadeIn {
            constructor() {
                this.fromTo = [
                    { transform: "translate(0, -100%)", opacity: 0},
                    { transform: "translate(0, 0)"}
                ];
                this.timming = {
                    duration: 400,
                    iterations: "1",
                    easing: "ease-out",
                };
            }          
        }
        class FadeOut {
            constructor() {
                this.fromTo = [
                    { transform: "translate(0, 0)", opacity: 1},
                    { transform: "translate(0, 100%)", opacity: 0}
                ];
                this.timming = {
                    duration: 400,
                    iterations: "1",
                    easing: "ease-out",
                };
            }          
        }

        if(removed) {
            removed.style.opacity = 0;
        }
        
        if( removed ) {
            const fadeOut = new FadeOut();
            const fadeOutAnim = () => {
                removed.animate( fadeOut.fromTo, fadeOut.timming );                    
            }
            fadeOutAnim();
            
        }
        if( adding ) {
            const fadeIN = new FadeIn();
            const fadeINAnim = () => {
                adding.animate( fadeIN.fromTo, fadeIN.timming );
            }
            fadeINAnim();
        }       
        
    }, [roundCache])

    return( 
        <div className="product-container" id={`product-container-${index}`} ref={productContainer} key={index}>
            {products[index + (showNum* (roundCache % maxRound))]}
            {products[index + (showNum* ((roundCache-1) % maxRound))]}
        </div>
    )
}



const Product = ({name = "--", stockID = "--", price = "--", change = "--", changePercent = "--"}) => {
    const product = useRef(null);
    const [changeColor, setChangeColor] = useState(1);

    const color = ["#00CE53", "#202022", "#FF5858"];
    const [ changePercentFixed, setChangePercentFixed ] = useState(changePercent);

    useEffect( () => {
        let fixed = parseFloat(changePercentFixed).toFixed(2);
        setChangePercentFixed( fixed );
    }, [changePercentFixed])
    
    
    useEffect(() => {
        const changeNum = parseFloat(change);
        if(changeNum > 0){
            setChangeColor(2);
        } else if(changeNum < 0){
            setChangeColor(0);
        } else{
            setChangeColor(1);
        }
    }, [change])

    return(
        <div className="product" ref={product}>
            <h4 className="product-name">{name}</h4>
            <span className="product-price" style={{color: color[changeColor]}}>{price}</span>
            <span className="product-change" style={{color: color[changeColor]}}>{change > 0? `+${change}` : `${change}`}</span>
            <span className="product-change-percent" style={{color: color[changeColor]}}>{changePercent > 0? `+${changePercentFixed}` : `${changePercentFixed}`}%</span>
        </div>
    )
}