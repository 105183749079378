import { useState, useEffect, useRef, useContext } from "react";
// import { useNavigate, useLocation, Link } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import LocationSelector from "./LocationSelector";
import FinacialProducts from "./FinacialProducts";
import JsScrollAnim from "./JsScrollAnim"
import "./Header.css";
import { LocationContext } from "../../App";

const Header = () => {
    const [headerSpace, setHeaderSpace] = useState(0);
    const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
    const [isTaiwan, setIsTaiwan] = useState(true);
    const headerSpacerRef = useRef(null);
    // const navigate = useNavigate();
    const { languageBook, urlData} = useContext(LocationContext)

    useEffect( () => {
        const setHeaderSpacerHeight = () => {
            if (headerSpacerRef.current) {
                const header = document.querySelector("header");
                const height = header.clientHeight;
                const style = window.getComputedStyle(header);
                const marginTop = parseFloat(style.marginTop);
                const marginBottom = parseFloat(style.marginBottom);
                setHeaderSpace(height + marginTop + marginBottom);
            }
        }

        setHeaderSpacerHeight();
        window.addEventListener("resize", setHeaderSpacerHeight);
    },[])

    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }else{
            window.scrollTo(0, 0);
        }

        JsScrollAnim();
      }, [location]);

    const closeMenu = () => {
        setHeaderMenuOpen(false);
    }

    useEffect(() => {
        const location = urlData.location;

        if(location === "") {
            setIsTaiwan(true);
        } else {
            setIsTaiwan(false);
        }
    }, [urlData])
    

    return(
        <>
            <header>
                <FinacialProducts />
                <div className="stander-header" id="header">
                    
                    <Link to={`${urlData.location}/`} id="brand-wrapper" className="link-wrapper pointer" onClick={closeMenu}>
                        <img className="header-logo" src="/logo/un077-logo-white-light.svg" alt="un077-logo" />
                        <p id="brand-name">{languageBook.siteTitle}</p>
                    </Link>
                    
                    <HeaderNav headerMenuOpen={headerMenuOpen} setHeaderMenuOpen={setHeaderMenuOpen} isTaiwan={isTaiwan} />
                    
                    {isTaiwan && (<Link to="/anti-scam" id="anti-scam-link" onClick={closeMenu}>
                        {languageBook.antiScheme}
                    </Link>)}
                    
                    <Link to={`${urlData.location}/`} id="home-link" onClick={closeMenu}>
                        {languageBook.aboutUs}
                    </Link>
                    
                    <Link to={`${urlData.location}/#line-sec`} id="scta" onClick={closeMenu}>
                        {languageBook.contactUs}
                    </Link>

                    {isTaiwan && (<Link to="/apply-now" id="apply-now" className="button" onClick={closeMenu}>
                        {languageBook.applyNow}
                    </Link>)}
                              
                    
                    <LocationSelector setHeaderMenuOpen={setHeaderMenuOpen} />

                    <p id="product-note">報價延遲15分鐘</p>

                    <div id="backdrop">
                        
                    </div>
                    
                </div>
            </header>
            
            <div id="header-spacer"
                 ref={headerSpacerRef}
                 style={{height: headerSpace}}
            ></div>
        </>
        
        
    );
}
export default Header;