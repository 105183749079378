import Header from "./components/Header";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import "./AntiScam.css";
import { useContext } from "react";
import { LocationContext } from "../App";

const AntiScam = () => {
    const {languageBook, urlData} = useContext(LocationContext);

    return(
        <>
            <Helmet>              
                <html lang={languageBook.lang} />
                <title>{`${languageBook.antiScheme} | ${languageBook.siteTitle}`}</title>
                <meta name="description" content={languageBook.seoDescAntiScheme} />
                <meta name="keywords" content={languageBook.seoKeywordsAntiScheme}></meta>
                <link ref="canonical" href={`https://unite-information.com${urlData.location}${urlData.page}`} />

                <meta name="og:url" content={`https://unite-information.com${urlData.location}${urlData.page}`} />
                <meta property="og:title" content={`${languageBook.antiScheme} | ${languageBook.siteTitle}`} />
                <meta property="og:image" content="/pictures/un077-logo-white-light-square.png" />
                <meta property="og:description" content={languageBook.seoDescAntiScheme} />

                <link rel="alternate" hreflang="zh-TW" href="https://unite-information.com/anti-scam" />
                {/* <link rel="alternate" hreflang="en-US" href="https://unite-information.com/en-us/anti-scam" />
                <link rel="alternate" hreflang="ja-JP" href="https://unite-information.com/ja-jp/anti-scam" /> */}
            </Helmet>
            <Header />
            <main>
                <section className="reading-sec">
                    <h1 id="anti-scam-h1" className="text-center">{languageBook.antiScheme}</h1>
                    <h2>{languageBook.antiFraudRemind}</h2>
                    <p>
                        {languageBook.antiFraudDesc}
                    </p>
                </section>
                <section className="reading-sec">
                    <video id="futures-video" className="show-video" poster="/pictures/anti-fraud-snapshot.png" controls loop muted>
                        <source src="https://storage.googleapis.com/un077-official.appspot.com/20240818_unite-information_anti-fraud.mp4" type="video/mp4" />
                        您的瀏覽器不支援 HTML5 視訊。
                    </video>
                </section>
                <section className="reading-sec">
                    
                    {/* <a href="https://www.youtube.com/watch?v=Ws1Ym3boJLU" target="_blank" rel="noopener noreferrer">反詐騙宣導影片</a> */}
                    <div >
                        <iframe id="anti-scam-iframe" title="futures-video" type="text/html" src="https://www.youtube.com/embed/Ws1Ym3boJLU?version=3&loop=1&playlist=Ws1Ym3boJLU&autoplay=1&rel=0&mute=1&origin=http://example.com"></iframe>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default AntiScam;