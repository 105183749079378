import './App.css';
import { Route, Routes, useLocation, Link } from 'react-router-dom';
import { createContext, useEffect, useState} from 'react';
import LocationOBJ from './page-components/components/LocationOBJ';
import Home from "./page-components/Home";
import Futures from './page-components/Futures';
import Stock from './page-components/Stock';
import ApplyNow from './page-components/ApplyNow';
import AntiScam from './page-components/AntiScam';
import Error404 from './page-components/Error404';
import NotAvailable from './page-components/NotAvailable';

import langZhHant from "./languages/zh-Hant.json";
import langEn from "./languages/en.json";
import langJa from "./languages/ja.json";
import StockLoginPage from './page-components/StockLoginPage';

// import CursorCross from './page-components/components/CursorCross';

export const LocationContext = createContext();

function App() {
  const url = useLocation();
  const taiwan = new LocationOBJ("taiwan", "台灣", "", 318);
  const japan = new LocationOBJ("japan", "日本", "/ja-jp", 290);
  const us = new LocationOBJ("us", "U.S.", "/en-us", 150);
  const allLocations = {
      taiwan: taiwan,
      japan: japan,
      us: us,
  };
  const [currentLocation, setCurrentLocation] = useState(taiwan);
  const [urlData, setUrlData] = useState({
    location: "",
    page: "/",
    name: "taiwan",
  })

  const [languageBook, setLanguageBook] = useState(langZhHant);

  useEffect(() => {
    if(urlData.location === "/en-us") {
      setLanguageBook(langEn);
      // setCurrentLocation(allLocations.us);
    } else if (urlData.location === "/ja-jp") {
      setLanguageBook(langJa);
      // setCurrentLocation(allLocations.japan);
    } else {
      setLanguageBook(langZhHant);
      // setCurrentLocation(allLocations.taiwan);
    }
  }, [urlData])

  // useEffect(() => {
  //   const location = currentLocation.id;
  //   const urlName = url.pathname;

  //   if(location !== "taiwan") {

  //     if( urlName !== "/" && urlName !=="/futures-teaching") {
  //       navigate("/");
  //     }
  //   }
  // }, [currentLocation, url, navigate])

  // format url
  useEffect(() => {

    // get location url
    let locationURL = url.pathname.match(/\/[a-z][a-z]-[a-z][a-z]/gm);
    let pageURL;
    let name;

    if( !locationURL ) {
      locationURL = [""];
      pageURL = url.pathname;
    } else {
      pageURL = url.pathname.slice(6);
    }

    if(locationURL[0] === "/en-us") {
      name = "U.S.";
    } else if (locationURL[0] === "/ja-jp") {
      name = "日本";
    } else {
      name = "台灣";
    }
    
    setUrlData({
      location: locationURL[0],
      page: pageURL,
      name: name,
    })
}, [url])


  return (
    <LocationContext.Provider value={{allLocations, currentLocation, setCurrentLocation, languageBook, urlData}}>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/en-us/' element={<Home />} />
        <Route exact path='/ja-jp/' element={<Home />} />

        <Route path='/futures-teaching' element={<Futures />} />
        <Route path='/en-us/futures-teaching' element={<Futures />} />
        <Route path='/ja-jp/futures-teaching' element={<Futures />} />

        <Route path='/stock-teaching' element={<Stock />} />
        <Route path='/en-us/stock-teaching' element={<NotAvailable />} />
        <Route path='/ja-jp/stock-teaching' element={<NotAvailable />} />
        <Route path='/stock-teaching/stock-login' element={<StockLoginPage />} />


        <Route path='/apply-now' element={<ApplyNow />} />
        <Route path='/anti-scam' element={<AntiScam />} />
        <Route path='*' element={<Error404 />} />
      </Routes>

      
      <div id='for-react-snap' style={{display: "none"}}>
        <Link to="/en-us/"></Link>
        <Link to="/ja-jp/"></Link>
        <Link to="/en-us/futures-teaching"></Link>
        <Link to="/ja-jp/futures-teaching"></Link>
      </div>
      {/* <CursorCross /> */}
    </LocationContext.Provider>
    
    
  );
}

export default App;
