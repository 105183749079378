import { useState, useRef, useEffect, useContext } from "react";
import { LocationContext } from "../../App";
import {  useNavigate } from "react-router-dom";
import "./LocationSelector.css";

const LocationSelector = ({setHeaderMenuOpen}) => {
    const {allLocations, setCurrentLocation, urlData} = useContext(LocationContext);


    const selector = useRef(null);
    const button = useRef(null);

    const navigate = useNavigate();
    
    const [isSelecting, setIsSelecting] = useState(false);

    useEffect(() => {
        const locationList = Array.from(selector.current.children);
        const btnRef = button.current;
        const handleClick = () => {
            setIsSelecting(true);
        }
        const clickClose = (event) => {
            if(!button.current?.contains(event.target)) {
                setIsSelecting(false);
            }            
        }
        const handleSelect = (event) => {
            const key = event.target.getAttribute("data-key");
            setCurrentLocation(allLocations[key]); 

            let newUrlLocation;
            if(key === "us") {
                newUrlLocation = "/en-us";
            } else if(key === "japan") {
                newUrlLocation = "/ja-jp";
            } else {
                newUrlLocation = "";
            }

            navigate(`${newUrlLocation}${urlData.page}`);
        }

        button.current.addEventListener("click", handleClick);
        document.addEventListener("click", clickClose);
        locationList.forEach(location => {
            location?.addEventListener("click", handleSelect)
        })

        
        return() => {
            btnRef.removeEventListener("click", handleClick);
            document.removeEventListener("click", clickClose);
            locationList?.forEach(location => {
                location?.removeEventListener("click", handleSelect)
            })
        }
    }, [selector, isSelecting, allLocations, setCurrentLocation, urlData, navigate])

    useEffect(() => {
        isSelecting? selector.current?.classList.add("selecting") : selector.current?.classList.remove("selecting");
    }, [selector, isSelecting]);

    const closeMenu = () => {
        setHeaderMenuOpen(false);
    }    

    return(
        <div id="selector-container">
            <button ref={button} id="current-location">
                <img src="/pictures/language_24dp_FILL0_wght200_GRAD0_opsz24.svg" alt="loaction" />
                <p key="urldata-name">{urlData.name}</p>
            </button>            
            <ul ref={selector} id="location-select">
                {Object.keys(allLocations).map(key => (
                    <li id={key} data-key={key} key={key} onClick={closeMenu}>
                        {allLocations[key].name}                      
                    </li>
                    // <>
                    //     <li id={key} data-key={key} key={key} onClick={closeMenu}>
                    //         {allLocations[key].name}                      
                    //     </li>
                    //     <li id={key} data-key={key} key={`fake-${key}`} onClick={closeMenu} style={{display: "none"}}>
                    //         <Link to={`${allLocations[key].locationUrl}/`} key={`fake-link-${key}`}>{allLocations[key].name}</Link>                        
                    //     </li>
                    // </>
                    
                ))}
            </ul>
        </div>
        
    );
}

export default LocationSelector;