import { forwardRef, useState, useRef, useContext, useEffect } from "react";
import { Login } from "./StockLoginAPI";
import "./StockLogin.css";
import { LocationContext } from "../../App";

const StockLogin = forwardRef((props, ref) => {
    const [userId, setUserId] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    // const goToStockDia = useRef(null);
    const newPageLink = useRef(null);
    // const [toStockDia, setToStockDia] = useState(false);
    const [isLogin, setIsLogin] = useState(false)
    // const [cfUrl, setCfUrl] = useState("");
    const [id, setID] = useState("");
    const [token, setToken] = useState("");
    
    // useEffect(() => {
    //     const goToStockDiaCurrent = goToStockDia.current;
    //     // if(goToStockDiaCurrent) {
    //     //     toStockDia? goToStockDiaCurrent.showModal() : goToStockDiaCurrent.close();
    //     // }        
    //     setCfUrl(getConfirmedURL(id, token));
    // }, [toStockDia, id, token])

    useEffect(() => {
        setIsLogin(false);
    }, [])

    useEffect(() => {
        if(isLogin) {
            alert("登入成功，即將跳轉至新頁面")
            newPageLink.current.click();
        }        
    }, [isLogin, id, token])

    // const closeGoToStockDia = () => {
    //     setToStockDia(false);
    // }

    // const openNewStockPage = () => {
    //     window.open( getConfirmedURL(id, token), "", "noopener,noreferrer" );
    // }


    const {languageBook} = useContext(LocationContext);

    const idInput = useRef(null);
    const passwordInput = useRef(null);

    const handleIdOnChange = (e) => {
        setUserId(e.target.value)
    }
    const handlePasswordOnChange = (e) => {
        setUserPassword(e.target.value)
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const cleanInput = () => {
        setUserId("");
        setUserPassword("");
    }

    

    const closeDialog = () => {
        const dialog = document.querySelector("#stock-login");
        if(!dialog){
            return;
        }else{
            dialog.close();
        }
    }

    return(
        <>
            <dialog id="stock-login" ref={ref}>
                <h2>{languageBook.stockLogin}</h2>
                <button id="close" onClick={closeDialog}>
                    <img src="/pictures/close_24dp_FILL0_wght400_GRAD0_opsz24.svg" alt="close" />
                </button>
                <form action="">
                    <label htmlFor="">{languageBook.stockAccount}</label>
                    <input ref={idInput} type="text" value={userId} onChange={handleIdOnChange} required autoComplete="username" />
                    <label htmlFor="">{languageBook.stockPassword}</label>
                    <div id="password-wrapper">
                        <input ref={passwordInput} type={showPassword? "text" : "password"} value={userPassword} onChange={handlePasswordOnChange} required autoComplete="current-password" />
                        <div onClick={toggleShowPassword}>
                            <img src={showPassword? "/pictures/visibility_off_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" : "/pictures/visibility_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"} alt="show password" />
                        </div>
                    </div>
                    
                    <button type="submit" onClick={(e) => Login(e, userId, userPassword, cleanInput, setID, setToken, setIsLogin)}>{languageBook.stockLoginBTN}</button>
                </form>
            </dialog>
            {/* <dialog id="go-to-stock" ref={goToStockDia}>
                <p>將跳轉至股票頁面</p>
                <a href={cfUrl} className="button" target="_blank" rel="noopener noreferrer"
                    // onMouseUp={closeGoToStockDia}  
                    ref={newPageLink}
                    onClick={openNewStockPage}        
                >
                    跳轉
                </a>
            </dialog> */}
        </>
        
    )
})

export default StockLogin;