import { useEffect } from "react";

const StockAPI = ( { setFinacialInfo } ) => {
    const wsURL = "wss://kaihsin-finacial-data.info/websocket/stock";

    useEffect(() => {
        const websocket = new WebSocket( wsURL );

        websocket.addEventListener( "open", () => {
            console.log("Websocket Connected");
            const authMessage = {
                event: "auth",
                data: {
                    api: "pleaseABC",
                    request: "finacial-data"
                }
            }
            websocket.send( JSON.stringify( authMessage ) );
        })

        websocket.addEventListener( "message", (e) => {
            if( !e ){
                console.log( "Get No Message From Server" );
                return;
            }

            const wsData = JSON.parse( e.data );

            if( wsData.event === "finacial-data") {
                // console.log( wsData.data );
                setFinacialInfo( wsData.data );
            } else {
                console.log( `From WebSocket Server: ${wsData.data.message}`)
            }
        })

        websocket.addEventListener( "error", ( error ) => {
            console.log( `WebSocket Connection Error: ${error}` );
        })
    }, [setFinacialInfo])
    

}

export default StockAPI;