// import { Link, useNavigate } from "react-router-dom";
import { Link, } from "react-router-dom";
import "./ArrowBTN.css";

const ArrowBTN = ({buttonText, index, onHover, addClass, url}) => {
    // const navigate = useNavigate();
    return(
        <Link to={`${url}`}
            id={"arrow-btn-" + index} 
            className={"arrow-btn text-center " + addClass} 
            onMouseEnter={onHover} 
            index={index}
        >
            {buttonText}
            <img className="arrow-in-btn" src="/pictures/chevron_right_24dp_FILL0_wght400_GRAD0_opsz24.svg" alt="go-arrow" />
        </Link>
        // <button id={"arrow-btn-" + index} 
        //         className={"arrow-btn text-center " + addClass} 
        //         onMouseEnter={onHover} 
        //         index={index}
        //         onClick={() => navigate(`${url}`)}
        // >
        //     {buttonText}
        //     <img className="arrow-in-btn" src="/pictures/chevron_right_24dp_FILL0_wght400_GRAD0_opsz24.svg" alt="go-arrow" />
        // </button>
    )
}

export default ArrowBTN;