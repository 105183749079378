import "./IconBTN.css"

const IconBTN = ({imgSrc, imgName, btnText, link="", isNewPage}) => {
    return(
        <button className="icon-btn">
            <a href={link} target={isNewPage? "_blank": ""} rel="noreferrer">
                <img src={imgSrc} alt={imgName} />
                <span>{btnText}</span>
            </a>            
        </button>
    )
}

export default IconBTN