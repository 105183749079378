import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { hydrate, render } from "react-dom";


const rootElement = document.getElementById("root");

const APP = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

if (rootElement.hasChildNodes()) {
  // 使用新的 hydrateRoot 來替代 hydrate
  ReactDOM.hydrateRoot(
    rootElement,
    APP
  );
} else {
  // 使用 createRoot 來替代 render
  ReactDOM.createRoot(rootElement).render(
    APP
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
